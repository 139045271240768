<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="course_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('course')">
                            <esl-course-selectbox v-model="form.course_id"></esl-course-selectbox>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="start_date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('start_date')">
                            <select-date
                                v-model="form.start_date"
                                :is-time="false"
                                :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="end_date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('end_date')">
                            <select-date
                                v-model="form.end_date"
                                :is-time="false"
                                :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="evaluation_id" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('evaluation')">
                            <evaluation-selectbox v-model="form.evaluation_id"></evaluation-selectbox>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="classroom_id" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('classroom')">
                            <classroom-selectbox v-model="form.classroom_id" :validateError="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="instructor_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('teaching_staff')">
                            <staff-auto-complete v-model="form.instructor_id"
                                                 :valueObject="instructorValueObject"
                                                 :validateError="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
        </ValidationObserver>
        <div class="col-12 mt-3 d-flex">
            <b-button
                @click="updateForm"
                type="button"
                variant="primary"
                class="btn-lg mr-2"
            >
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
//Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
//Service
import SessionService from "@/services/SessionService";
//Components
import EvaluationSelectbox from "@/components/interactive-fields/EvaluationSelectbox.vue";
import EslCourseSelectbox from "@/components/interactive-fields/EslCourseSelectbox.vue";
import ClassroomSelectbox from "@/components/interactive-fields/ClassroomSelectbox.vue";
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";


export default {
    components: {
        ClassroomSelectbox,
        ValidationProvider,
        ValidationObserver,
        EvaluationSelectbox,
        EslCourseSelectbox,
        StaffAutoComplete
    },
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formLoading: false,
            form: {
                course_id: null,
                start_date: null,
                end_date: null,
                evaluation_id: null,
                classroom_id: null,
                instructor_id: null,
            },
            instructorValueObject: {},
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            this.formLoading = true;
            SessionService.get(id)
                .then((response) => {
                    this.form = response.data.data;
                    this.instructorValueObject = {
                        value: this.form.instructor_id,
                        text: this.form.instructor_name + ' ' + this.form.instructor_surname
                    }
                })
                .catch((error) => {
                    if (error.data.message) {
                        this.$toast.error(this.$t("api." + error.data.message));
                    }
                }).finally(() => {
                this.formLoading = false;
            });
        },
        async updateForm() {
            if (this.checkPermission('session_update')) {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;
                    SessionService.update(this.formId, this.form)
                        .then((response) => {
                            this.formLoading = false;
                            this.$toast.success(this.$t("api." + response.data.message));
                            this.$emit("updateFormSuccess")
                        })
                        .catch((error) => {
                            this.showErrors(error, this.$refs.formModalValidate)
                        }).finally(() => {
                        this.formLoading = false;
                    })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        }
    }
}
</script>
