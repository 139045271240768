<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('session')"
                        :isNewButton="checkPermission('session_store')"
                        @new-button-click="createFormShow"
                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('session')"
                              :isNewButton="checkPermission('session_store')"
                              @new-button-click="createFormShow"
                              @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
            >
                <b-row>
                    <b-col sm="6">
                        <b-form-group :label="$t('course')">
                          <esl-course-selectbox v-model="datatable.queryParams.filter.course_id"></esl-course-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('start_date')">
                            <select-date v-model="datatable.queryParams.filter.start_date"></select-date>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('end_date')">
                            <select-date v-model="datatable.queryParams.filter.end_date">
                            </select-date>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('evaluation')">
                            <evaluation-selectbox v-model="datatable.queryParams.filter.evaluation_id"></evaluation-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                :lineNumbers="false"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            >
            </datatable>

            <CommonModal ref="showModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()" v-if="checkPermission('session_show')">
                <template v-slot:CommonModalTitle>
                    {{ $t('show').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formId="formId"
                               v-if="formProcess=='show'"
                    />
                </template>
            </CommonModal>

            <CommonModal ref="createFormModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="checkPermission('session_store')">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toUpperCase() }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess"
                                 v-if="formProcess=='create'"
                    />
                </template>
            </CommonModal>
            <CommonModal ref="updateFormModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="checkPermission('session_update')">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toUpperCase() }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess=='update'"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    //Template
    import AppLayout from "@/layouts/AppLayout";
    import Header from "@/layouts/AppLayout/Header"
    import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
    //Component
    import DatatableFilter from "@/components/datatable/DatatableFilter";
    import Datatable from "@/components/datatable/Datatable";
    import CommonModal from "@/components/elements/CommonModal";
    import EvaluationSelectbox from "@/components/interactive-fields/EvaluationSelectbox.vue";
    import EslCourseSelectbox from "@/components/interactive-fields/EslCourseSelectbox.vue";
    //Other
    import qs from "qs";
    //Service
    import SessionService from "@/services/SessionService";
    //Page
    import CreateForm from "./CreateForm"
    import UpdateForm from "./UpdateForm"
    import ShowForm from "./ShowForm"

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,

            DatatableFilter,
            Datatable,
            CommonModal,

            CreateForm,
            UpdateForm,
            ShowForm,

            EvaluationSelectbox,
            EslCourseSelectbox,
        },
        metaInfo() {
            return {
                title: this.$t("session"),
            };
        },
        data() {
            return {
                selectCode: null,
                formId: 0,
                form: {},
                formProcess: null,
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: "",
                            field: "buttons",
                            html: true,
                            sortable: false,
                            tdClass: "p-0 text-center w-40 align-middle",
                            buttons: [
                                {
                                    text: this.$t('show'),
                                    class: 'ri-search-eye-line',
                                    permission: 'session_show',
                                    callback: (row) => {
                                        this.showForm(row.id);
                                    }
                                },
                                {
                                    text: this.$t("edit"),
                                    class: "ri-edit-box-line",
                                    permission: 'session_update',
                                    callback: (row) => {
                                        this.loadData(row.id);
                                    },
                                },
                                {
                                    text: this.$t("delete"),
                                    class: "ri-delete-bin-2-line",
                                    permission: 'session_delete',
                                    callback: (row) => {
                                        this.delete(row.id);
                                    },
                                },
                            ],
                        },
                        {
                            label: this.$t("id"),
                            field: "id",
                            sortable: true,
                            hidden: true
                        },
                        {
                            label: this.$t("course"),
                            field: "course_name",
                            sortable: true,
                        },
                        {
                            label: this.$t("start_date"),
                            field: "start_date",
                            sortable: true,
                        },
                        {
                            label: this.$t("end_date"),
                            field: "end_date",
                            sortable: true,
                        },
                        {
                            label: this.$t("evaluation"),
                            field: "evaluation_name",
                            sortable: true,
                        },
                        {
                            label: this.$t("classroom"),
                            field: "classroom_name",
                            sortable: true,
                        },
                        {
                            label: this.$t("teaching_staff"),
                            field: "instructor_id",
                            sortable: true,
                            formatFn: (value, row) => {
                                return row.instructor_name + ' ' + row.instructor_surname
                            },
                        },
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: "id",
                        page: 1,
                        limit: 20,
                    },
                },
            };
        },
        methods: {
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            filterClear() {
                this.datatable.queryParams.filter = {};
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == "desc" ? "-" : "";
                this.datatable.queryParams.sort = sortType + params[0].field;
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams,
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                };

                return SessionService.getAll(config)
                                       .then((response) => {
                                           this.datatable.rows = response.data.data;
                                           this.datatable.total = response.data.pagination.total;
                                       })
                                       .finally(() => {
                                           this.datatable.isLoading = false;
                                       });
            },
            loadData(id) {
                this.formProcess = "update";
                this.formId = id;
                SessionService.get(id)
                                .then((response) => {
                                    this.form = response.data.data;
                                    this.$refs.updateFormModal.$refs.commonModal.show();
                                })
                                .catch((error) => {
                                    if (error.data.message) {
                                        this.$toast.error(this.$t("api." + error.data.message));
                                    }
                                });
            },
            delete(id) {
                this.$swal
                  .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                  })
                  .then((result) => {
                    if (result.isConfirmed) {
                      SessionService.del(id)
                          .then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message))
                            this.getRows()
                          })
                          .catch(error => {
                            this.$toast.error(this.$t('api.' + error.data.message))
                          })
                    }
                  })
            },
            async createFormSuccess() {
                this.$refs.createFormModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },
            async updateFormSuccess() {
                this.$refs.updateFormModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },
            createFormShow() {
                this.formId = null
                this.form = {};
                this.formProcess = 'create'
                this.$refs.createFormModal.$refs.commonModal.show()
            },
            formClear() {
                this.formId = null
                this.formProcess = null
            },
            showForm(id) {
                this.formId = id
                this.formProcess = 'show'
                this.$refs.showModal.$refs.commonModal.show()
            },
        },
    };
</script>

